import React, {useContext, useEffect, useRef, useState} from "react";
import "./StartupProjects.scss";
import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

const ImageContainer = ({project, imageContainerWidth}) => {
  const [index, setindex] = useState(1);
  const [position, setPosition] = useState("0px");
  useEffect(() => {
    let intervalId = setInterval(() => {
      setindex(prev => (prev < project.image.length ? prev + 1 : 1));
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let number = index - 1;
    setPosition(number * imageContainerWidth + "px");
  }, [index]);

  return (
    <div
      style={{
        width: imageContainerWidth + "px",
        display: "flex",

        position: "relative",
        right: position,
        transition: "all ease-in 0.5s"
      }}
    >
      {project.image.map(img => (
        <img
          src={img}
          alt={project.projectName}
          style={{
            minWidth: imageContainerWidth + "px",
            maxHeight: "250px",
            background: "white"
          }}
        />
      ))}
    </div>
  );
};

export default function StartupProject() {
  const imageContainer = useRef();
  const [imageContainerWidth, setImageContainerWidth] = useState();

  useEffect(() => {
    if (imageContainer.current && window) {
      setImageContainerWidth(imageContainer.current.offsetWidth);
      console.log(imageContainer.current.offsetWidth);
      window.addEventListener("resize", () => {
        setImageContainerWidth(imageContainer.current.offsetWidth);
      });
    }
  }, []);
  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  const {isDark} = useContext(StyleContext);
  if (!bigProjects.display) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">{bigProjects.title}</h1>
          <p
            style={{marginTop: "35px", marginBottom: "35px"}}
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {bigProjects.subtitle}
          </p>

          <div className="projects-container">
            {bigProjects.projects.map((project, i) => {
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  <div
                    ref={imageContainer}
                    style={{overflow: "hidden", padding: "0", width: "100%"}}
                  >
                    {project.image.length > 0 ? (
                      <ImageContainer
                        project={project}
                        imageContainerWidth={imageContainerWidth}
                      />
                    ) : null}
                  </div>

                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.projectDesc}
                    </p>
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <span
                              key={i}
                              className={
                                isDark ? "dark-mode project-tag" : "project-tag"
                              }
                              onClick={() => openUrlInNewTab(link.url)}
                            >
                              {link.name}
                            </span>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
